<template>
  <v-sheet tile>
    <section id="contact-hero">
      <!-- dekstop -->
      <div
        v-show="$vuetify.breakpoint.lgAndUp"
        class="mx-auto w-full"
        height="580px"
        style="margin-top: -65px"
      >
        <v-img
          fullscreen
          src="/img/contact/contact-bg.png"
          min-height="580px"
          max-height="580px"
          :position="windowWidth < 768 ? '35% 25%' : 'center 25%'"
          gradient="0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)"
        >
          <v-layout column style="margin-top: 200px; margin-left: 100px">
            <span
              class="homeTitleNew white--text montserrat"
              style="font-size: 68px; line-height: 85px"
              >{{ lang[getLanguage].FAQ_WELCOME_TITLE }}<br />
              {{ lang[getLanguage].FAQ_WELCOME_DESCRIPTION }}</span
            >
            <p class="mt-2"></p>
            <p class="mt-2"></p>
          </v-layout>
        </v-img>
      </div>

      <!-- mobile -->
      <div
        v-show="$vuetify.breakpoint.mdAndDown"
        class="mx-auto"
        height="600px"
        style="margin-top: -65px"
      >
        <v-img
          fullscreen
          src="/img/contact/contact-bg-mobile.png"
          min-height="600px"
          max-height="600px"
          gradient="0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)"
        >
          <v-layout class="container justify-center">
            <span
              class="white--text montserrat"
              style="
                font-size: 36px;
                letter-spacing: -0.03em;
                font-weight: 700;
                margin-top: 6em;
              "
              >{{ lang[getLanguage].FAQ_WELCOME_TITLE }}<br />
              {{ lang[getLanguage].FAQ_WELCOME_DESCRIPTION }}</span
            >
          </v-layout>
        </v-img>
      </div>
    </section>

    <!-- <v-layout column align-center v-if="loadingData">
      <p class="mt-2"></p>
      <v-progress-circular indeterminate color="#673AB7"></v-progress-circular>
    </v-layout>
    <v-snackbar :timeout="4000" :bottom="'top'" v-model="snackbar" color="primary">
      <v-icon color="green">mdi-check-circle-outline</v-icon>
      <span>{{ snackbarText }}</span>
      <v-btn text color="#00D2D7" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar> -->
    <div id="faq-scroll-auto" class=""></div>
    <!-- section two dekstop -->
    <section v-if="!isMobileDevice" class="my-16 px-28">
      <div class="container p-0">
        <p class="montserrat font-weight-bold mb-16" style="font-size: 36px">
          {{ lang[getLanguage].FAQ_TITLE }}
        </p>
        <v-row class="mt-6">
          <v-tabs
            v-model="tabsAskDeks"
            vertical
            class="flex gap-x-20 custom-tabs-nonborder"
          >
            <div class="w-80">
              <v-tab
                :class="
                  tabsAskDeks == 0
                    ? 'text-charged-purple-300 font-semibold text-lg font-work-sans'
                    : 'text-charged-grey-500 font-normal text-lg font-work-sans'
                "
                style="
                  justify-content: unset;
                  text-transform: initial;
                  border: none !important;
                  letter-spacing: -0.36px;
                "
              >
                {{ lang[getLanguage].FAQ_TOP }}
                <v-icon
                  class="ml-auto"
                  right
                  :style="
                    tabsAskDeks == 0
                      ? 'color: #6B4693 !important;'
                      : 'color: #C1C2C4 !important'
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-tab>
              <v-tab
                :class="
                  tabsAskDeks == 1
                    ? 'text-charged-purple-300 font-semibold text-lg font-work-sans'
                    : 'text-charged-grey-500 font-normal text-lg font-work-sans'
                "
                style="
                  justify-content: unset;
                  text-transform: initial;
                  letter-spacing: -0.36px;
                "
              >
                Product
                <v-icon
                  class="ml-auto"
                  right
                  :style="
                    tabsAskDeks == 1
                      ? 'color: #6B4693 !important;'
                      : 'color: #C1C2C4 !important'
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-tab>
              <v-tab
                :class="
                  tabsAskDeks == 2
                    ? 'text-charged-purple-300 font-semibold text-lg font-work-sans'
                    : 'text-charged-grey-500 font-normal text-lg font-work-sans'
                "
                style="
                  justify-content: unset;
                  text-transform: initial;
                  letter-spacing: -0.36px;
                "
              >
                {{ lang[getLanguage].FAQ_SUBCRIPTION }}
                <v-icon
                  class="ml-auto"
                  right
                  :style="
                    tabsAskDeks == 2
                      ? 'color: #6B4693 !important;'
                      : 'color: #C1C2C4 !important'
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-tab>
              <v-tab
                :class="
                  tabsAskDeks == 3
                    ? 'text-charged-purple-300 font-semibold text-lg font-work-sans'
                    : 'text-charged-grey-500 font-normal text-lg font-work-sans'
                "
                style="
                  justify-content: unset;
                  text-transform: initial;
                  letter-spacing: -0.36px;
                "
              >
                Aftersales
                <v-icon
                  class="ml-auto"
                  right
                  :style="
                    tabsAskDeks == 3
                      ? 'color: #6B4693 !important;'
                      : 'color: #C1C2C4 !important'
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-tab>
            </div>

            <!-- tabs -->
            <v-tab-item class="-mt-12">
              <v-card flat>
                <v-card-text>
                  <span style="color: #ffff">{{ (index = 0) }}</span>
                  <div
                    class="faq-content"
                    v-for="item in lang[getLanguage].TOP.QUESTION"
                    :key="item.question"
                  >
                    <div class="faq-question">
                      <input
                        :id="'top' + index"
                        type="checkbox"
                        class="panel"
                      />
                      <!-- <div class="plus right-5">+</div> -->
                      <label
                        :for="'top' + index"
                        class="w-full inline-block work-sans text-lg cursor-pointer font-medium text-charged-grey-100 relative"
                      >
                        {{ item }}
                        <span class="btn-toggle-collaps"></span>
                      </label>
                      <div class="work-sans panel-content ml-0">
                        {{ lang[getLanguage].TOP.ANSWER[index] }}
                      </div>
                    </div>
                    <span style="color: #ffff">{{ index++ }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item class="-mt-12">
              <v-card flat>
                <v-card-text>
                  <span style="color: #ffff">{{ (index = 0) }}</span>
                  <div
                    class="faq-content"
                    v-for="item in lang[getLanguage].PRODUCT.QUESTION"
                    :key="item.question"
                  >
                    <div class="faq-question">
                      <input
                        :id="'product' + index"
                        type="checkbox"
                        class="panel"
                      />
                      <!-- <div class="plus right-5">+</div> -->
                      <label
                        :for="'product' + index"
                        class="work-sans w-full inline-block work-sans text-lg cursor-pointer font-medium text-charged-grey-100 relative"
                      >
                        {{ item }}
                        <span class="btn-toggle-collaps"></span>
                      </label>
                      <div class="work-sans panel-content ml-0">
                        {{ lang[getLanguage].PRODUCT.ANSWER[index] }}
                      </div>
                    </div>
                    <span style="color: #ffff">{{ index++ }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item class="-mt-12">
              <v-card flat>
                <v-card-text>
                  <span style="color: #ffff">{{ (index = 0) }}</span>
                  <div
                    class="faq-content"
                    v-for="item in lang[getLanguage].SUBSCRIPTION.QUESTION"
                    :key="item.question"
                  >
                    <div class="faq-question">
                      <input
                        :id="'subscription' + index"
                        type="checkbox"
                        class="panel"
                      />
                      <!-- <div class="plus right-5">+</div> -->
                      <label
                        :for="'subscription' + index"
                        class="work-sans w-full inline-block work-sans text-lg cursor-pointer font-medium text-charged-grey-100 relative"
                      >
                        {{ item }}
                        <span class="btn-toggle-collaps"></span>
                      </label>
                      <div class="work-sans panel-content ml-0">
                        {{ lang[getLanguage].SUBSCRIPTION.ANSWER[index] }}
                      </div>
                    </div>
                    <span style="color: #ffff">{{ index++ }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item class="-mt-12">
              <v-card flat>
                <v-card-text>
                  <span style="color: #ffff">{{ (index = 0) }}</span>
                  <div
                    class="faq-content"
                    v-for="item in lang[getLanguage].AFTERSALES.QUESTION"
                    :key="item.question"
                  >
                    <div class="faq-question">
                      <input
                        :id="'aftersales' + index"
                        type="checkbox"
                        class="panel"
                      />
                      <!-- <div class="plus right-5">+</div> -->
                      <label
                        :for="'aftersales' + index"
                        class="work-sans w-full inline-block work-sans text-lg cursor-pointer font-medium text-charged-grey-100 relative"
                      >
                        {{ item }}
                        <span class="btn-toggle-collaps"></span>
                      </label>
                      <div class="work-sans panel-content ml-0">
                        {{ lang[getLanguage].AFTERSALES.ANSWER[index] }}
                      </div>
                    </div>
                    <span style="color: #ffff">{{ index++ }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- end tabs -->
          </v-tabs>
        </v-row>
      </div>
    </section>

    <!-- section two mobile -->
    <section v-if="isMobileDevice" class="my-16">
      <v-container class="p-0">
        <p
          class="montserrat font-weight-bold"
          style="font-size: 28px; padding: 20px"
        >
          {{ lang[getLanguage].FAQ_TITLE }}
        </p>
      </v-container>

      <v-card class="mt-6">
        <v-tabs v-model="tabsAskMobile" grow class="-ml-8">
          <v-tab
            :class="
              tabsAskMobile == 0
                ? 'border rounded-full bg-charged-purple-300 h-10 mr-2 font-work-sans font-normal text-base'
                : 'border rounded-full bg-white h-10 mr-2'
            "
            :style="
              tabsAskMobile == 0
                ? 'justify-content: unset; text-transform: initial; color: white; background: #6B4693 !important; letter-spacing: -0.36px;'
                : 'justify-content: unset; text-transform: initial; letter-spacing: -0.36px;'
            "
          >
            {{ lang[getLanguage].FAQ_TOP }}
          </v-tab>
          <v-tab
            :class="
              tabsAskMobile == 1
                ? 'border rounded-full bg-charged-purple-300 h-10 mr-2 font-work-sans font-normal text-base'
                : 'border rounded-full bg-white h-10 mr-2'
            "
            :style="
              tabsAskMobile == 1
                ? 'justify-content: unset; text-transform: initial; color: white; background: #6B4693 !important; letter-spacing: -0.36px;'
                : 'justify-content: unset; text-transform: initial; letter-spacing: -0.36px;'
            "
          >
            Product
          </v-tab>
          <v-tab
            :class="
              tabsAskMobile == 2
                ? 'border rounded-full bg-charged-purple-300 h-10 mr-2 font-work-sans font-normal text-base'
                : 'border rounded-full bg-white h-10 mr-2'
            "
            :style="
              tabsAskMobile == 2
                ? 'justify-content: unset; text-transform: initial; color: white; background: #6B4693 !important; letter-spacing: -0.36px;'
                : 'justify-content: unset; text-transform: initial; letter-spacing: -0.36px;'
            "
          >
            {{ lang[getLanguage].FAQ_SUBCRIPTION }}
          </v-tab>
          <v-tab
            :class="
              tabsAskMobile == 3
                ? 'border rounded-full bg-charged-purple-300 h-10 mr-2 font-work-sans font-normal text-base'
                : 'border rounded-full bg-white h-10 mr-2'
            "
            :style="
              tabsAskMobile == 3
                ? 'justify-content: unset; text-transform: initial; color: white; background: #6B4693 !important; letter-spacing: -0.36px;'
                : 'justify-content: unset; text-transform: initial; letter-spacing: -0.36px;'
            "
          >
            Aftersales
          </v-tab>

          <v-tab-item class="ml-12">
            <v-card flat class="w-full">
              <div class="w-full">
                <span style="color: #ffff">{{ (index = 0) }}</span>
                <div
                  class="faq-content"
                  v-for="item in lang[getLanguage].TOP.QUESTION"
                  :key="item.question"
                >
                  <div class="faq-question">
                    <input
                      :id="'product' + index"
                      type="checkbox"
                      class="panel"
                    />
                    <!-- <div class="plus -right-0.5" style="font-size: 25px">+</div> -->
                    <label
                      :for="'product' + index"
                      class="work-sans inline-block text-base text-charged-grey-100 font-medium relative"
                      style="width: 95%; letter-spacing: -0.32px"
                    >
                      {{ item }}
                      <span class="btn-toggle-collaps"></span>
                    </label>
                    <div
                      class="work-sans panel-content ml-0"
                      style="line-height: 24px; letter-spacing: -0.32px"
                    >
                      {{ lang[getLanguage].TOP.ANSWER[index] }}
                    </div>
                  </div>
                  <span style="color: #ffff">{{ index++ }}</span>
                </div>
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item class="ml-12">
            <v-card flat class="w-full">
              <div class="w-full">
                <span style="color: #ffff">{{ (index = 0) }}</span>
                <div
                  class="faq-content"
                  v-for="item in lang[getLanguage].PRODUCT.QUESTION"
                  :key="item.question"
                >
                  <div class="faq-question">
                    <input
                      :id="'product' + index"
                      type="checkbox"
                      class="panel"
                    />
                    <!-- <div class="plus -right-0.5" style="font-size: 25px">+</div> -->
                    <label
                      :for="'product' + index"
                      class="work-sans inline-block text-base text-charged-grey-100 font-medium relative"
                      style="width: 95%; letter-spacing: -0.32px"
                    >
                      {{ item }}
                      <span class="btn-toggle-collaps"></span>
                    </label>
                    <div
                      class="work-sans panel-content ml-0"
                      style="line-height: 24px; letter-spacing: -0.32px"
                    >
                      {{ lang[getLanguage].PRODUCT.ANSWER[index] }}
                    </div>
                  </div>
                  <span style="color: #ffff">{{ index++ }}</span>
                </div>
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item class="ml-12">
            <v-card flat class="w-full">
              <div class="w-full">
                <span style="color: #ffff">{{ (index = 0) }}</span>
                <div
                  class="faq-content"
                  v-for="item in lang[getLanguage].SUBSCRIPTION.QUESTION"
                  :key="item.question"
                >
                  <div class="faq-question">
                    <input
                      :id="'subscription' + index"
                      type="checkbox"
                      class="panel"
                    />
                    <!-- <div class="plus -right-0.5" style="font-size: 25px">+</div> -->
                    <label
                      :for="'subscription' + index"
                      class="work-sans inline-block text-base text-charged-grey-100 font-medium relative"
                      style="width: 95%; letter-spacing: -0.32px"
                    >
                      {{ item }}
                      <span class="btn-toggle-collaps"></span>
                    </label>
                    <div
                      class="work-sans panel-content ml-0"
                      style="line-height: 24px; letter-spacing: -0.32px"
                    >
                      {{ lang[getLanguage].SUBSCRIPTION.ANSWER[index] }}
                    </div>
                  </div>
                  <span style="color: #ffff">{{ index++ }}</span>
                </div>
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item class="ml-12">
            <v-card flat class="w-full">
              <div class="w-full">
                <span style="color: #ffff">{{ (index = 0) }}</span>
                <div
                  class="faq-content"
                  v-for="item in lang[getLanguage].AFTERSALES.QUESTION"
                  :key="item.question"
                >
                  <div class="faq-question">
                    <input
                      :id="'aftersales' + index"
                      type="checkbox"
                      class="panel"
                    />
                    <!-- <div class="plus -right-0.5" style="font-size: 25px">+</div> -->
                    <label
                      :for="'aftersales' + index"
                      class="work-sans inline-block text-base text-charged-grey-100 font-medium relative"
                      style="width: 95%; letter-spacing: -0.32px"
                    >
                      {{ item }}
                      <span class="btn-toggle-collaps"></span>
                    </label>
                    <div
                      class="work-sans panel-content ml-0"
                      style="line-height: 24px; letter-spacing: -0.32px"
                    >
                      {{ lang[getLanguage].AFTERSALES.ANSWER[index] }}
                    </div>
                  </div>
                  <span style="color: #ffff">{{ index++ }}</span>
                </div>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </section>

    <section
      id="launch-contact"
      style="background: #6b4693"
      class="px-md-7 w-full"
    >
      <v-row
        justify="space-around"
        class="pt-16 pb-16 px-2 w-full m-0"
        align="center"
      >
        <v-col cols="12" md="8" class="md:pl-20">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <v-card-title
            :class="
              'px-0 text-over-image-white py-0 ' +
              (windowWidth <= 768 ? 'charged-title-mobile' : 'charged-title')
            "
            style="word-break: break-word; letter-spacing: -0.7px"
          >
            <span
              class="font-montserrat text-2xl md:text-1xl font-bold"
              v-html="lang[getLanguage].PL_CONTACT_TITLE"
            ></span>
          </v-card-title>
        </v-col>
        <v-col cols="12" md="4" class="md:pr-20 md:flex md:justify-end">
          <v-btn
            :width="windowWidth <= 768 ? '100%' : 'fit-content'"
            color="white"
            :class="
              'charged-button-text no-uppercase py-md-3 px-md-8 capitalize' +
              (windowWidth <= 768
                ? 'charged-button capitalize'
                : 'charged-button-mobile capitalize')
            "
            x-large
            @click="startChat()"
            style="
              color: #6b4693;
              font-family: Work Sans;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: -0.32px;
            "
            elevation="2"
          >
            {{ lang[getLanguage].PL_CONTACT_BUTTON }}
            <v-icon right color="primary"> mdi-whatsapp </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" class="md:pl-20">
          <!-- <p v-html="
              windowWidth < 768
                ? lang[getLanguage].PL_CONTACT_TEXT_MOBILE
                : lang[getLanguage].PL_CONTACT_TEXT
            " class="charged-body-h2 text-over-image-white"></p> -->
          <v-row>
            <v-col cols="12" md="2">
              <p
                class="text-over-image-white font-semibold font-work-sans text-xl"
                style="letter-spacing: -0.4px"
              >
                DistriX Number
              </p>
              <p class="charged-body-h2 text-over-image-white">
                <a
                  href="tel:+6281190069696"
                  class="text-decoration-none white--text text-lg font-normal"
                  style="letter-spacing: -0.36px"
                  >(+62) 811 900 69696</a
                >
              </p>
            </v-col>

            <!-- <v-col cols="2">
                <p class="charged-body-h2 text-over-image-white font-weight-bold">
                Scale Number
                </p>
                <p class="charged-body-h2 text-over-image-white">
                  <a href="tel:+628239060090" class="text-decoration-none white--text">(+62) 812 3906 0090</a>
                </p>
              </v-col> -->

            <v-col cols="12" md="2">
              <p
                class="text-over-image-white font-semibold font-work-sans text-xl"
                style="letter-spacing: -0.4px"
              >
                Media Number
              </p>
              <p class="charged-body-h2 text-over-image-white">
                <a
                  href="tel:+6281339955011"
                  class="text-decoration-none white--text text-lg font-normal"
                  style="letter-spacing: -0.36px"
                  >(+62) 813 3995 5011</a
                >
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <div class="md:hidden h-0.5 bg-white mx-4 mb-10"></div>
      <div class="pb-16 md:px-16">
        <div class="md:px-2">
          <p v-if="getLanguage == 'en'" class="text-white px-4">
            Or you can also send an email to
            <a style="color: white" href="mailto:contact@charged.asia">
              contact@charged.asia
            </a>
          </p>
          <p v-if="getLanguage == 'id'" class="text-white px-4">
            Atau Anda juga dapat mengirim email ke
            <a style="color: white" href="mailto:contact@charged.asia">
              contact@charged.asia
            </a>
          </p>
        </div>
      </div> -->
    </section>

    <!-- <section class="px-2 pt-20">
      <v-container class="mx-auto m-0 w-full md:px-20 md:pt-3.5">
        <p
          class="montserrat font-weight-bold mb-6 md:text-4xl text-1xl"
          style="line-height: 45px"
        >
          {{ lang[getLanguage].FAQ_LOCATIONS_GSHED }}
        </p>
        <v-row class="md:flex">
          <v-col cols="12" md="6" class="p-0 md:pr-8">
            <v-col>
              <img src="/img/contact/factory-cikupa.jpg" alt="" width="100%" />
            </v-col>
            <v-col>
              <h3
                class="montserrat font-weight-bold"
                style="font-size: 20px; line-height: 28px"
              >
                Cikupa
              </h3>
              <p
                class="text-uppercase work-sans"
                style="
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  color: #858688;
                "
              >
                Giga-Shed
              </p>
              <p
                class="work-sans text-charged-grey-300"
                style="font-size: 16px; line-height: 22px; font-weight: 500"
              >
                PT Trivesta Polymas Perkasa, Jl. Bhumimas IV No.8, Talaga,
                Cikupa, Tangerang Regency, Banten 15710
              </p>

              <v-btn
                href="https://goo.gl/maps/UoWrKWW1cpqCvWCF9"
                target="_blank"
                text
                color="primary"
                class="font-semibold no-background-hover mb-4"
                style="
                  font-size: 16px;
                  letter-spacing: 0.32px;
                  text-transform: initial;
                  padding: 0;
                "
              >
                {{ lang[getLanguage].FAQ_MAPS }}
                <v-icon right color="primary">mdi-chevron-right</v-icon>
              </v-btn>

              <p
                class="text-uppercase work-sans"
                style="
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  color: #858688;
                "
              >
                {{ lang[getLanguage].FAQ_OPENING_TITLE }}
              </p>
              <p
                class="work-sans text-charged-grey-300"
                style="font-size: 16px; line-height: 24px; font-weight: 500"
              >
                {{ lang[getLanguage].FAQ_OPENING_TIME }}<br />{{
                  lang[getLanguage].FAQ_CLOSE_TIME
                }}
              </p>
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </section> -->
    <div class="md:hidden py-8"></div>
    <section class="px-2 pb-20 md:mt-16">
      <v-container class="mx-auto m-0 w-full md:px-20 md:pb-3.5">
        <p
          class="montserrat font-weight-bold mb-6 md:text-4xl text-1xl"
          style="line-height: 45px"
        >
          {{ lang[getLanguage].FAQ_LOCATIONS_ESHED }}
        </p>
        <v-row justify="between" class="md:flex md:flex-nowrap">
          <v-col cols="12" md="6" class="p-0 md:pr-8">
            <v-col>
              <img src="/img/contact/office-kemang.jpg" alt="" width="100%" />
            </v-col>
            <v-col>
              <h3
                class="montserrat font-weight-bold"
                style="font-size: 20px; line-height: 28px"
              >
                Kemang
              </h3>
              <p
                class="text-uppercase work-sans"
                style="
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  color: #858688;
                "
              >
                E-Shed
              </p>
              <p
                class="work-sans text-charged-grey-300"
                style="font-size: 16px; line-height: 22px; font-weight: 500"
              >
                Jl. Kemang Utara VII No. 21B, <br />
                Mampang Prapatan, Jakarta Selatan, DKI Jakarta 12730
              </p>

              <v-btn
                href="https://goo.gl/maps/tyr9qML1EprBWFaF7"
                target="_blank"
                text
                color="primary"
                class="font-semibold no-background-hover mb-4"
                style="
                  font-size: 16px;
                  letter-spacing: 0.32px;
                  text-transform: initial;
                  padding: 0;
                "
              >
                {{ lang[getLanguage].FAQ_MAPS }}
                <v-icon right color="primary">mdi-chevron-right</v-icon>
              </v-btn>

              <p
                class="text-uppercase work-sans"
                style="
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  color: #858688;
                "
              >
                {{ lang[getLanguage].FAQ_OPENING_TITLE }}
              </p>
              <p
                class="work-sans text-charged-grey-300"
                style="font-size: 16px; line-height: 24px; font-weight: 500"
              >
                {{ lang[getLanguage].FAQ_OPENING_TIME }}<br />
                {{ lang[getLanguage].FAQ_CLOSE_TIME }}
              </p>
            </v-col>
          </v-col>
          <v-col cols="12" md="6" class="p-0 md:pr-8">
            <v-col>
              <img src="/img/contact/factory-cikupa.jpg" alt="" width="100%" />
            </v-col>
            <v-col>
              <h3
                class="montserrat font-weight-bold"
                style="font-size: 20px; line-height: 28px"
              >
                Cikupa
              </h3>
              <p
                class="text-uppercase work-sans"
                style="
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  color: #858688;
                "
              >
                E-Shed
              </p>
              <p
                class="work-sans text-charged-grey-300"
                style="font-size: 16px; line-height: 22px; font-weight: 500"
              >
                PT Trivesta Polymas Perkasa, Jl. Bhumimas IV No.8, Talaga,
                Cikupa, Tangerang Regency, Banten 15710
              </p>

              <v-btn
                href="https://goo.gl/maps/UoWrKWW1cpqCvWCF9"
                target="_blank"
                text
                color="primary"
                class="font-semibold no-background-hover mb-4"
                style="
                  font-size: 16px;
                  letter-spacing: 0.32px;
                  text-transform: initial;
                  padding: 0;
                "
              >
                {{ lang[getLanguage].FAQ_MAPS }}
                <v-icon right color="primary">mdi-chevron-right</v-icon>
              </v-btn>

              <p
                class="text-uppercase work-sans"
                style="
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  color: #858688;
                "
              >
                {{ lang[getLanguage].FAQ_OPENING_TITLE }}
              </p>
              <p
                class="work-sans text-charged-grey-300"
                style="font-size: 16px; line-height: 24px; font-weight: 500"
              >
                {{ lang[getLanguage].FAQ_OPENING_TIME }}<br />{{
                  lang[getLanguage].FAQ_CLOSE_TIME
                }}
              </p>
            </v-col>
          </v-col>
          <!-- <v-col cols="12" md="6" class="p-0 md:pl-8">
            <v-col>
              <img src="/img/contact/eshed-kebayoran.jpg" alt="" width="100%" />
            </v-col>
            <v-col>
              <h3
                class="montserrat font-weight-bold"
                style="font-size: 20px; line-height: 28px"
              >
                Kebayoran
              </h3>
              <p
                class="text-uppercase work-sans"
                style="
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  color: #858688;
                "
              >
                E-Shed
              </p>
              <p
                class="work-sans text-charged-grey-300"
                style="font-size: 16px; line-height: 22px; font-weight: 500"
              >
                Jl. Hang Lekir I No.1, RW.3, Gelora, Kec. Kby. Baru, Kota
                Jakarta Pusat, Daerah Khusus Ibukota Jakarta
              </p>

              <v-btn
                href="https://goo.gl/maps/YAUbvFwHM57ypj9W7"
                target="_blank"
                text
                color="primary"
                class="font-semibold no-background-hover mb-4"
                style="
                  font-size: 16px;
                  letter-spacing: 0.32px;
                  text-transform: initial;
                  padding: 0;
                "
              >
                {{ lang[getLanguage].FAQ_MAPS }}
                <v-icon right color="primary">mdi-chevron-right</v-icon>
              </v-btn>

              <p
                class="text-uppercase work-sans"
                style="
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 400;
                  color: #858688;
                "
              >
                {{ lang[getLanguage].FAQ_OPENING_TITLE }}
              </p>
              <p
                class="work-sans text-charged-grey-300"
                style="font-size: 16px; line-height: 24px; font-weight: 500"
              >
                {{ lang[getLanguage].FAQ_OPENING_TIME }}<br />{{
                  lang[getLanguage].FAQ_CLOSE_TIME
                }}
              </p>
            </v-col>
          </v-col> -->
        </v-row>
      </v-container>
    </section>

    <!-- <Newsletter id="options" :isMobileDevice="isMobileDevice" :dark="dark" :drawer="drawer" :windowWidth="windowWidth">
    </Newsletter> -->

    <!-- <v-container class="my-6">
      <v-row :class="windowWidth < 768 ? 'flex-column mt-8' : 'mt-8'">
        <v-col col="12" md="6">
          <p class="text-h4 font-weight-bold">Contact Our Sales Team</p>
          <p>We are available on:</p>
          <p>Weekdays : 09.00 - 18:00 (WIB)</p>
          <p>Weekends : 09.00 - 15:00 (WIB)</p>
          <v-divider class="my-8"></v-divider>
          <v-row class="mb-2">
            <v-col cols="1" class="py-0">
              <v-icon>mdi-map</v-icon>
            </v-col>
            <v-col cols="11" class="py-0">
              <p class="mb-0">Showroom location</p>
            </v-col>
          </v-row>

          <v-row class="mb-2">
            <v-col cols="1" class="py-0"></v-col>
            <v-col cols="11" class="py-0">
              <p class="primary-text mb-0">
                Jl. Cikupamas Raya No.8, Cikupa, Tangerang, Banten
              </p>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="1" class="py-0">
              <v-icon>mdi-phone</v-icon>
            </v-col>
            <v-col cols="11" class="py-0">
              <p class="mb-0">
                Phone Number :
                <span class="primary-text">+62 2159404888</span>
              </p>
            </v-col>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="1" class="py-0">
              <v-icon>mdi-email</v-icon>
            </v-col>
            <v-col cols="11" class="py-0">
              <p class="mb-0">
                Email Address :
                <span class="primary-text">yo@charged.asia</span>
              </p>
            </v-col>
          </v-row>
        </v-col>

        <v-col col="12" md="6">
          <v-card elevation="2">
            <v-card-text class="pa-6" style="postion:absolute;">
              <v-row>
                <v-col col="12" md="12">
                  <p>
                    Full Name*
                  </p>
                  <v-text-field class="py-0" v-model="name" label="" outlined solo dense hide-details="true">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col col="12" md="6">
                  <p>
                    Email*
                  </p>
                  <v-text-field class="py-0" v-model="email" label="" outlined solo dense hide-details="true">
                  </v-text-field>
                </v-col>
                <v-col col="12" md="6">
                  <p>
                    Phone Number*
                  </p>
                  <v-text-field class="py-0" v-model="phone" label="" outlined solo dense hide-details="true">
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col col="12" md="12">
                  <p>
                    Please select a topic*
                  </p>
                  <v-combobox v-model="selectedTopic" :items="topics" class="py-0" solo outlined dense
                    hide-details="true"></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col col="12" md="12">
                  <p>
                    How can I help you*
                  </p>
                  <v-textarea v-model="comment" auto-grow hide-details="true" outlined class="py-0" dense
                    placeholder="Tell us more about your concern and requests. We will be here to help you." rows="4">
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col col="12" md="12">
                  <v-btn width="100%" color="primary" :disabled="disableButton()" @click="submit()">Submit</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container> -->

    <!-- <Footer id="footer" :isMobileDevice="isMobileDevice" :dark="dark" :drawer="drawer" :windowWidth="windowWidth">
    </Footer> -->

    <v-footer class="pa-0">
      <Footer
        id="footer"
        :isMobileDevice="isMobileDevice"
        :dark="dark"
        :drawer="drawer"
        :windowWidth="windowWidth"
      >
      </Footer>
    </v-footer>
  </v-sheet>
</template>

<script>
//import firebase from "firebase/app";
import { db } from "@/main";
//import store from "@/store/index";
// import Newsletter from "./Newsletter";
// import Footer from "./Footer";
import Footer from "./FooterPressLaunch";

export default {
  name: "Contact",
  props: {
    dark: Boolean,
    drawer: Boolean,
    isMobileDevice: Boolean,
    windowWidth: Number,
    windowHeight: Number,
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
  },
  data() {
    return {
      tabsAskDeks: 0,
      tabsAskMobile: 0,
      lang: this.$store.state.lang,
      name: "",
      phone: "",
      email: "",
      topics: [],
      comment: "",
      selectedTopic: "",
      disableBtn: true,
      //Snackbars
      snackbar: false,
      snackbarText: "",
      tab: null,
      items: [
        {
          tab: "Top Question",
          content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error facilis, vel ullam itaque, quo quam eveniet dicta temporibus impedit odio eligendi consectetur adautem consequatur cum deleniti eaque nobis exercitationem?",
        },
        {
          tab: "Subscription",
          content: "Tab 2 Content",
        },
        {
          tab: "Pickup & Delivery",
          content: "Tab 3 Content",
        },
        {
          tab: "Riding Experience",
          content: "Tab 4 Content",
        },
        {
          tab: "Legal & Security",
          content: "Tab 5 Content",
        },
      ],
    };
  },
  components: {
    // Newsletter,
    Footer,
  },
  watch: {},
  created() {
    this.topics = [
      "Ex: I have a feature request",
      "Report a bug",
      "Question about electric vehicle and going electric",
      "Having trouble signing in and signing up",
      "Legal and Security",
      "Billing questions",
      "Something is not working",
      "Other",
    ];
    this.reset();
  },
  methods: {
    startChat() {
      var link = "https://api.whatsapp.com/send?phone=6281237686970&text=";
      if (this.getLanguage === "en") {
        link += "Hello%20Charged";
      }
      if (this.getLanguage === "id") {
        link += "Halo%20Charged";
      }
      window.open(link, "_blank");
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    reset() {
      this.name = "";
      this.phone = "";
      this.email = "";
      this.comment = "";
      this.selectedTopic = this.topics[0];
    },
    disableButton() {
      if (
        this.name !== "" &&
        this.phone !== "" &&
        this.email !== "" &&
        this.comment !== "" &&
        this.selectedTopic !== this.topics[0]
      ) {
        return false;
      }
      return true;
    },
    validateSubmit() {
      if (
        this.name === "" &&
        this.phone === "" &&
        this.email === "" &&
        this.comment === "" &&
        this.selectedTopic === this.topics[0]
      ) {
        this.snackbarText = "Please fill the form correctly.";
        this.snackbar = true;
        return false;
      }
      return true;
    },
    submit() {
      this.loadingData = true;
      if (this.validateSubmit() === false) {
        this.loadingData = false;
        return;
      }
      this.newPostKey = db.collection("ticket").doc();
      let dispatchObj = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        topic: this.selectedTopic,
        comment: this.comment,
        read: false,
        solve: false,
        sendToEmail: false,
        saveToERP: false,
        created: new Date().getTime(),
      };
      db.collection("ticket")
        .doc(this.newPostKey.id)
        .set(dispatchObj)
        .then(() => {
          this.loadingData = false;
          this.snackbarText = "Ticket submitted successfully!";
          this.snackbar = true;
        })
        .catch((error) => {
          alert(error);
          this.snackbarText = "Something went wrong!";
          this.snackbar = true;
        });
      this.reset();
    },
  },
};
</script>

<style>
.primary-text {
  color: #6b4693;
}

.contactText {
  font-family: "Montserrat";
  font-size: 44px;
}

.wrapper {
  background: #fafafa;
  display: flex;
  justify-content: center;
}

.sectionHeader {
  font-family: "Comfortaa";
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}

.cardsH {
  background: #fff;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: grid;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}

.cardsV {
  background: #fff;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  grid-gap: 1rem;
  display: table-column;
  grid-auto-flow: column;
  min-height: 300px;
  max-height: 350px;
}

.mapouter {
  position: relative;
  text-align: center;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
}

/* DESQY ADD THIS */

/* FONT */
p {
  font-family: "Work Sans";
}

.montserrat {
  font-family: "Montserrat" !important;
}

.work-sans {
  font-family: "Work Sans";
}

/* END FONT */

.text-over-image-white {
  color: #ffffff !important;
}

.charged-title {
  font-family: "Montserrat" !important;
  font-size: 36px !important;
  font-weight: 700 !important;
  line-height: 45px !important;
  letter-spacing: -0.03em !important;
  text-align: left !important;
}

.charged-title-2 {
  font-family: "Montserrat" !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 35px !important;
  letter-spacing: -0.03em !important;
  text-align: left !important;
}

.charged-title-mobile {
  font-family: "Montserrat" !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 36px !important;
  letter-spacing: -0.025em !important;
  text-align: left !important;
}

.charged-body-h1 {
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #353638;
}

.charged-body-h2 {
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #353638;
}

.charged-button-text {
  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
}

.charged-factory-title {
  /* Title H2/Bold */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  /* or 125% */
  align-items: center;
  letter-spacing: -0.025em;

  /* Charged/Grey/900 */

  color: #ffffff;
}

.charged-factory-text {
  /* Body H2/Regular */

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  align-items: center;
  letter-spacing: -0.02em;

  /* Charged/Grey/600 */

  color: #e8e8e8;
}

.charged-rimba-title {
  /* Title H2/Bold */

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  /* identical to box height, or 125% */
  align-items: center;
  letter-spacing: -0.025em;

  /* Charged/Grey/100 */

  color: #262626;
}

.charged-rimba-text {
  /* Body H2/Regular */

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */
  align-items: center;
  letter-spacing: -0.02em;

  /* Charged/Grey/200 */

  color: #353638;
}

.charged-rimba-button {
  /* Body H2/Medium */

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */
  text-align: center;
  letter-spacing: -0.02em;

  /* Charged/Purple/300 */

  color: #6b4693;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.v-sheet.v-card {
  border-radius: none !important;
}

/* FAQ */

.faq-header {
  font-size: 42px;
  border-bottom: 1px dotted #ccc;
  padding: 24px;
}

.faq-content {
  margin: 0 auto;
}

.faq-question {
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
}

.panel-title {
  font-size: 18px;
  line-height: 26px;
  color: #262626;
  font-weight: 700;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 10px 10px 0 48px;
  display: block;
  cursor: pointer;
  margin-left: -30px;
}

.panel-content {
  font-size: 16px;
  padding: 0px 14px;
  line-height: 26px;
  font-weight: 400;
  color: #535456;
  margin: 0 40px;
  height: 0;
  overflow: hidden;
  z-index: 0;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
}

.panel:checked ~ .panel-content {
  height: auto;
  opacity: 1;
  padding: 14px;
}

.plus {
  position: absolute;
  margin-left: 930px;
  margin-top: 4px;
  z-index: 1;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.panel:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel {
  display: none;
}

.no-background-hover::before {
  background-color: transparent !important;
}

.custom-tabs-nonborder .v-tabs-slider-wrapper {
  display: none;
}

.btn-toggle-collaps::after {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 14px;
  height: 2px;
  right: 0;
  top: 15px;
  font-size: 25px;
  background: #262626;
}

.btn-toggle-collaps::before {
  content: "";
  position: absolute;
  /* margin-top: 15px; */
  width: 14px;
  height: 2px;
  right: 0;
  top: 15px;
  font-size: 25px;
  background: #262626;
  transform: rotate(90deg);
}

.panel:checked ~ .relative .btn-toggle-collaps::before {
  transform: rotate(0deg);
}

@media (max-width: 1025px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .plus {
    margin-left: 950px;
  }

  .panel-title {
    margin-left: -45px;
  }
}

@media (max-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .plus {
    margin-left: 700px;
  }

  .panel-title {
    margin-left: -45px;
  }
}

@media (max-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
  .plus {
    margin-left: 360px;
  }

  .panel-title {
    margin-left: -40px;
  }

  .btn-toggle-collaps::after {
    top: 10px;
    right: -18px;
  }

  .btn-toggle-collaps::before {
    top: 10px;
    right: -18px;
  }
}

/* END FAQ */
</style>